<template>
    <badge :type="badgeType">
      {{  text || status }}
    </badge>
  </template>
  
  <script>
  import {
    SYNC_STATUS_PENDING,
    SYNC_STATUS_SUCCESS,
    SYNC_STATUS_FAILED,
    ACTION_CREATE,
    ACTION_UPDATE,
    ACTION_DELETE
  } from "@/constants/syncLogs";
  
  export default {
    name: "SyncBadge",
    props: {
      status: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
    },
    computed: {
      badgeType() {
        switch (this.status) {
          case ACTION_CREATE:
            return "primary";
          case SYNC_STATUS_SUCCESS:
            return "success";
          case SYNC_STATUS_FAILED:
          case ACTION_DELETE:
            return "danger";
          case SYNC_STATUS_PENDING:
          case ACTION_UPDATE:
            return "warning";
          default:
            return "default";
        }
      },
    },
  };
  </script>
  